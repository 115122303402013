import { DataManager, WebMethodAdaptor } from '@syncfusion/ej2-data';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DragAndDrop, EventRenderedArgs, Inject, PopupOpenEventArgs, Resize, ResourceDirective, ResourcesDirective, ScheduleComponent, TimelineMonth, TimelineViews, ViewDirective, ViewsDirective, RenderCellEventArgs, Timezone} from '@syncfusion/ej2-react-schedule';
import React from 'react';
import useMultiRef from '../../hooks/useMultiRef';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import { IInstall, InstallUtils, InstallStatus } from '../../models/Install';
import { IResourceAllocation } from '../../models/ResourceAllocation';
import { IResourceGroup } from '../../models/ResourceGroup';
import globalNetsuiteService, { NetsuiteService } from '../../services/netsuite';
import { Color, ResourceType, ColorDescArray } from '../../shared/constants';
import { GenericSummary, InstallResourceSet, InstallHolidayList } from '../../shared/types';
import { InstallResource } from '../../models/ResourceGroup';
import { ResourceUtils } from '../../shared/utils';
import { InstallJobTypeLabel } from '../../models/Install';
import { UserContextState } from '../UserContext';
import { addClass, createElement} from '@syncfusion/ej2-base';
import './index.module.css';
import './style.css';
import { useDropzone } from 'react-dropzone'//Dropzone library added
import { ActionEventArgs } from '@syncfusion/ej2-react-schedule';
import { getCookie } from '../../services/netsuite';
import { ProgressBar } from '../App/ProgressBar';
import { View } from '@syncfusion/ej2-react-schedule';
import axios from 'axios';
import linkifyHtml from "linkify-html";



const dataSource = new DataManager({
    url: globalNetsuiteService.resolveEndpoint({ aaa_action: 'get-calendar-resource-scheduling' }),
    batchUrl: globalNetsuiteService.resolveEndpoint({ aaa_action: 'crud-calendar-resource-scheduling' }),
    adaptor: new WebMethodAdaptor(),
    crossDomain: true,
});

interface PostRequestResponse {
    success: boolean;
    message: string;
    error: any[];
}

interface EventEditorDynamicProps {
    installs: IInstall[];
    allResources: InstallResourceSet;
    allResourceGroups: IResourceGroup[];
    location: void | GenericSummary;
    allResourcesFiltered: InstallResource[];
    allResourceGroupsFiltered: IResourceGroup[];
}
type EventEditorProps = IResourceAllocationEvent & EventEditorDynamicProps;

const EventEditor = (props?: EventEditorProps) => {

    const resourceIdsRef = React.useRef<MultiSelectComponent>(null);
    const [selectedResourceIds, setSelectedResourceIds] = React.useState(props?.ResourceIds ?? []);

    const allResourceGroupTemp = props?.allResourceGroupsFiltered ?? [];
    var allResourceGroupList = allResourceGroupTemp.map(el => { return { id: el.id, text: el.name} });

    var [startTime, setStartTime] = React.useState<Date | undefined>(props?.StartTime ? new Date(props.StartTime) : undefined);
    var [endTime, setEndTime] = React.useState<Date | undefined>(props?.EndTime ? new Date(props.EndTime) : undefined);
    var [selectedGroup, setSelectedGroup] = React.useState(props?.resourceGroupId ?? "");
    var [selectedColor, setSelectedColor] = React.useState(props?.displayColor);
    var [installerNotesValue, setInstallerNotesValue] = React.useState(props?.installerNote ? props?.installerNote.toString() : "");
    var [attachmentNames, setAttachmentNames] = React.useState("");
    var [loadingState, setLoadingState] = React.useState(false);
    var userId = getCookie('empIdValue');
    var appVersion = "0.1.0";//App version from package.json file
    var userTokenValue = getCookie('user_token');
    let existingUploadedFiles: string = "";
    let uploadedFilesNameArr: string[] = [];
    let uploadedFilesURLArr: string[] = [];
    let tempFileHyperLinkObjArr: any[] = [];
    if (props?.jobDetails !== undefined && props.jobDetails?.attachment_names !== undefined && props.jobDetails?.attachment_paths !== undefined) {
        existingUploadedFiles = props.jobDetails?.attachment_names?.toString();
        // console.log("existingUploadedFiles", existingUploadedFiles);
        uploadedFilesNameArr = props.jobDetails?.attachment_names ? props.jobDetails.attachment_names.split(',') : [];
        uploadedFilesURLArr = props.jobDetails?.attachment_paths ? props.jobDetails.attachment_paths.split(',') : [];
        for (let i = 0; i < uploadedFilesNameArr.length; i++) {
            let fileObj = {};
            fileObj = { 'fileName': uploadedFilesNameArr[i], 'fileURL': uploadedFilesURLArr[i] };
            tempFileHyperLinkObjArr.push(fileObj);
        }
        // console.log("tempFileHyperLinkObjArr", tempFileHyperLinkObjArr);
    }
    var [fileHyperLinkObjArr, setFileHyperLinkObjArr] = React.useState(tempFileHyperLinkObjArr);

    // Dropzone variables
    const onDrop = React.useCallback(acceptedFiles => {
        // console.log("Inside onDrop() function, acceptedFiles", acceptedFiles);
        
        if (acceptedFiles.length > 0) {
            acceptedFiles.forEach((file: any) => {
                const reader = new FileReader()

                reader.readAsArrayBuffer(file)
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    console.log('one file loaded')
                }
            });
            fileUploadAndCreateRecord(acceptedFiles);
        }

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const fileUploadAndCreateRecord = async (files: any[]) => {
        setLoadingState(true);
        let uploadedFiles: any[] = [];
        // console.log("Inside fileUploadAndCreateRecord() function, uploading files:", files);
        let filesNameArr: string[] = [];
        let newFilesArray: any[] = [];
        let newFilesNameArr: string[] = [];
        let duplicateFiles: string[] = [];
        let clickResponse: boolean = true;

        for (let i = 0; i < files.length; i++) {
            let fileName = files[i].name;
            filesNameArr.push(fileName);
            if (existingUploadedFiles.includes(fileName)) {
                duplicateFiles.push(fileName);
            } else {
                newFilesArray.push(files[i]);
                newFilesNameArr.push(fileName);
            }
        }
        console.log("newFilesNameArr", newFilesNameArr);
        if (duplicateFiles.length > 0) {
            let fileNames = duplicateFiles.join(', ');
            clickResponse = window.confirm("The files: " + fileNames + " already exists. Click 'OK' to replace these files, or Click 'Cancel' to skip.");
        }
        console.log("clickResponse", clickResponse);
        if (clickResponse === false) {
            files = newFilesArray;// Use the file array after removing the duplicate items
            filesNameArr = newFilesNameArr;
        }
        setAttachmentNames( filesNameArr.join("  ") );
        console.log("files Array", files);
        for (let i = 0; i < files.length; i++) {
            let fileName = files[i].name;
            let fileType = files[i].type;
            let fileSize = files[i].size;
            let relatedJobRecordId = props?.InstallId;
            let fileURL = "" + process.env.REACT_APP_INSTALL_FILE_UPLOAD_LINK_BASE + process.env.REACT_APP_INSTALL_FILE_CONTAINER + '/' + relatedJobRecordId + '/' + fileName;
            let urlPath = fileURL + process.env.REACT_APP_INSTALL_FILE_UPLOAD_LINK_PARAMS;
            let headers =
            {
                'x-ms-blob-content-type': fileType,
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-meta-uploadedby': userId,
                'x-ms-meta-related_record_id': relatedJobRecordId,
                'x-ms-meta-appversion': appVersion,
            };
            await axios.put(urlPath, files[i], { headers }).then(res => {
                console.log("Response", res);
                if (res.status === 201) {//Status: Created
                    console.log("Successfully uploaded the file, FILE PATH:", fileURL);
                    if (!duplicateFiles.includes(fileName)) {
                        // console.log('File not belong to duplicate file list; Creating installation job attachment record in NetSuite');
                        const recordCreateResponse: any = createAttachmentRecord(fileName, fileType, fileURL, fileSize, relatedJobRecordId ? relatedJobRecordId : "");
                        console.log("Response obtained from NetSuite side, recordCreateResponse:", recordCreateResponse);
                        if (recordCreateResponse.success === true) uploadedFiles.push({ fileNameValue: fileName, fileSize: fileSize });
                    }
                } else {
                    alert("File upload failed. Please try uploading file:'" + fileName + "' again\n" + res);
                }
            }).catch(res => {
                console.log("ERR", res);
                alert("File upload failed. Please try uploading file:'" + fileName + "' again\n" + res);
            });
        }
        console.log('uploadedFiles', uploadedFiles);
        setLoadingState(false);
    }
 
    const NetSuiteServiceClassInstace = new NetsuiteService()//Instantiating NetsuiteService class to fetch functions

    const createAttachmentRecord = async (fileNameValue: string, fileTypeValue: string, fileURLValue: string, fileSizeValue: number, relatedJobRecordId: string) => {
        try {
            // console.log("Inside createAttachmentRecord, params:", { 'fileName': fileNameValue, 'fileType': fileTypeValue, 'fileURL': fileURLValue, 'fileSize': fileSizeValue, 'relatedJobRecordId': relatedJobRecordId, 'user_token': userTokenValue });
            const res: PostRequestResponse = await NetSuiteServiceClassInstace.post({
                aaa_action: 'crud-installation-job-attachment',
                action: 'create',
                fileName: fileNameValue,
                fileURL: fileURLValue,
                fileSize: fileSizeValue,
                fileType: fileTypeValue,
                relatedJobRecord: relatedJobRecordId,
                aaa_token: userTokenValue
            });
            console.log('Post Request Response', res);
            if (res.success === false) {
                alert("Installation Job Attachment record creation failed in NetSuite.\nRemoving uploaded file from File Upload Server.");
                const userTokenValue = getCookie('user_token');
                const res: PostRequestResponse = await NetSuiteServiceClassInstace.post({
                    aaa_action: 'crud-installation-job-attachment',
                    action: 'delete-azure',
                    fileURL: fileURLValue,
                    aaa_token: userTokenValue
                });
                console.log('Post Request Response', res);
                alert("Installation Job Attachment record deleted");
            }
            return res;
        } catch (err) {
            console.log("Error in createAttachmentRecord()", err);
            alert("Installation Job Attachment record creation failed in NetSuite.\nRemoving uploaded file from File Upload Server.");
            const userTokenValue = getCookie('user_token');
            const res: PostRequestResponse = await NetSuiteServiceClassInstace.post({
                aaa_action: 'crud-installation-job-attachment',
                action: 'delete-azure',
                fileURL: fileURLValue,
                aaa_token: userTokenValue
            });
            console.log('Post Request Response', res);
            alert("Installation Job Attachment record deleted");
            return { success: false, message: 'Error encountered while sending POST request to create file attachment record to NetSuite', error: [err] };
        }
    }
    
    const handleResourceChange = React.useCallback((selectedIds: string[] = [], type: ResourceType) => {

        console.log({ selectedIds, type })
        if (selectedIds !== null) {//new condition to avoid mapping over null values when change event triggered on reload
            setSelectedResourceIds(oldResourceIds => [
                ...oldResourceIds.filter(key => ResourceUtils.getInfoFromKey(key)[1] !== type),
                ...selectedIds.map(id => ResourceUtils.buildResourceKey(id, type))
            ]);
        }
    }, []);

    const handleResourceGroupChange = React.useCallback((selectedGroupId: string) => {
        if (selectedGroupId !== null) {
            setSelectedGroup(selectedGroup = selectedGroupId);
            // console.log('Inside handleResourceGroupChange selectedGroup', selectedGroup);
            const selectedGroupDeatils = props?.allResourceGroups.find(el => el.id === selectedGroup);
            var employeeList: string[] = [];
            employeeList = (selectedGroupDeatils?.employees)?.map(el => { return "EMP:" + el.id }) ? (selectedGroupDeatils?.employees)?.map(el => { return "EMP:" + el.id }) : [];
            console.log('employeeList', employeeList);
            if (employeeList.length !== 0) {
                handleResourceChange(employeeList, ResourceType.EMPLOYEE)
            }
            var equipmentList: string[] = [];
            equipmentList = (selectedGroupDeatils?.equipment)?.map(el => { return "EQP:" + el.id }) ? (selectedGroupDeatils?.equipment)?.map(el => { return "EQP:" + el.id }) : [];
            console.log('equipmentList', equipmentList);
            if (employeeList.length !== 0) {
                handleResourceChange(equipmentList, ResourceType.EQUIPMENT)
            }

        }
    }, [selectedGroup]
    );

    const deleteAttachmentRecord = async (attachmentURL: string, fileName: string | undefined, allFilesObjArr: any[]) => {
        try {
            const NetSuiteServiceClassInstace = new NetsuiteService()//Instantiating NetsuiteService class to fetch functions
            let deleteConfirmation: boolean = false;
            // console.log("Inside deleteAttachmentRecord, params:", { 'fileURL': attachmentURL });
            if (fileName !== undefined) {
                deleteConfirmation = window.confirm("Do you want to delete the attachment: " + decodeURI(fileName) + "?\nClick 'OK' to confirm, or Click 'Cancel' to skip.");
            } else {
                alert("Deleting Installation Job Attachment record");
                deleteConfirmation = true;
            }
            if (deleteConfirmation) {
                const userTokenValue = getCookie('user_token');
                const res: PostRequestResponse = await NetSuiteServiceClassInstace.post({
                    aaa_action: 'crud-installation-job-attachment',
                    action: 'delete',
                    fileURL: attachmentURL,
                    aaa_token: userTokenValue
                });
                console.log('Post Request Response', res);
                if (res.success === true && res.message.includes('Successfully deleted file attachment record in NetSuite:')) {
                    window.alert("Installation Job Attachment record deleted.\nClick save to update the files list.");
                    let newFileArr: any[] = [];
                    allFilesObjArr.map(el => {
                        if (el.fileName !== fileName && el.fileURL !== decodeURI(attachmentURL)) {
                            newFileArr.push(el);
                        }
                    });
                    console.log("Updated file object list", newFileArr)
                    setFileHyperLinkObjArr(fileHyperLinkObjArr = newFileArr);
                }
            } else {
                console.log('Post Request Response', { success: true, message: 'Deletion process cancelled by the User', error: [] });
            }
        } catch (err) {
            console.log("Error in deleteAttachmentRecord()", err);
            alert("Installation Job Attachment record deletion failed\n");
        }
    }

    if (typeof props === 'undefined') {
        return <div></div>
    }

    // Ensure all included 'ResourceIds' are accounted for
    // in the selected 'employee' and 'equipment' lists.
    // When opening the editor for a new appointment directly
    // from the calendar, only the 'ResourceIds' for the row
    // are provided.
    const employeeIds = props.EmployeeIds ?? [];
    const equipmentIds = props.EquipmentIds ?? [];
    const { employees, equipment } = ResourceUtils.fromResourceIds(props.ResourceIds);
    employees.filter(id => !employeeIds.includes(id)).forEach(id => employeeIds.push(id));
    equipment.filter(id => !equipmentIds.includes(id)).forEach(id => equipmentIds.push(id));
    


    // let colorKeys = Object.keys(Color);
    let colorDescriptionList = ColorDescArray;

    return <table className="custom-event-editor" cellPadding={5} style={{ width: '100%' }}>
        <tbody>
            <tr hidden={true}>
                <td className="e-textlabel">Install</td>
                <td colSpan={4}>
                    <DropDownListComponent
                        placeholder='Choose install'
                        name='InstallId'
                        data-name="InstallId"
                        className="e-field"
                        style={{ width: '100%' }}
                        dataSource={(props.installs as any).concat({ id: props.InstallId, tranId: 'JOB'+props.InstallId, jobName:'JOB'+props.InstallId }) as any}
                        fields={{ text: 'tranId', value: 'id' }}
                        value={props.InstallId ? props.InstallId : undefined}
                    >
                    </DropDownListComponent>
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">From</td>
                <td colSpan={4}>
                    <DateTimePickerComponent
                        id="StartTime"
                        format='MM/dd/yyyy hh:mm a'
                        data-name="StartTime"
                        value={startTime}
                        className="e-field"
                        change={evt => { setStartTime(startTime = evt?.value) }}
                    />
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">To</td>
                <td colSpan={4}>
                    <DateTimePickerComponent
                        id="EndTime"
                        format='MM/dd/yyyy hh:mm a'
                        data-name="EndTime"
                        value={endTime}
                        className="e-field"
                        change={evt => { setEndTime(endTime = evt?.value) }}
                    />
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">Team</td>
                <td colSpan={4}>
                    <DropDownListComponent
                        placeholder='Select Team'
                        id="EmployeeGroup"
                        data-name="EmployeeGroup"
                        dataSource={allResourceGroupList}
                        fields={{
                            text: 'text',
                            value: 'id',
                        }}
                        className="e-field"
                        change={evt => { handleResourceGroupChange(evt?.value as string) }}
                    />
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">Employees</td>
                <td colSpan={4}>
                    <MultiSelectComponent
                        id="EmployeeIds"
                        data-name="EmployeeIds"
                        // dataSource={props.allResources.employees as any}
                        dataSource={props.allResourcesFiltered as any}
                        mode="Default"
                        fields={{
                            text: 'text',
                            value: 'id',
                        }}
                        value={selectedResourceIds.filter(el => el.toString()?.trim().toUpperCase().startsWith('EMP'))}
                        placeholder="Employees"
                        className="e-field"
                        change={evt => { handleResourceChange(evt?.value as string[], ResourceType.EMPLOYEE) }}
                    />
                </td>
            </tr>


            <tr>
                {/* <tr className="hidden-input"> */}
                <td className="e-textlabel">Equipment</td>
                <td colSpan={4}>
                    <MultiSelectComponent
                        id="EquipmentIds"
                        data-name="EquipmentIds"
                        dataSource={props.allResources.equipment as any}
                        mode="Default"
                        fields={{
                            text: 'text',
                            value: 'id',
                        }}
                        value={selectedResourceIds.filter(el => el.toString()?.trim().toUpperCase().startsWith('EQP'))}
                        placeholder="Equipment"
                        className="e-field"
                        change={evt => { handleResourceChange(evt?.value as string[], ResourceType.EQUIPMENT) }}
                    />
                </td>
            </tr>

            {/* A dummy input field used to aggregate selected resources. Not visible to the user. */}
            <tr className="hidden-input">
                <td className="e-textlabel">Resources</td>
                <td colSpan={4}>
                    <MultiSelectComponent
                        ref={resourceIdsRef}
                        id="ResourceIds"
                        data-name="ResourceIds"
                        dataSource={props.allResourceGroups as any}
                        mode="Default"
                        fields={{
                            text: 'name',
                            value: 'id',
                        }}
                        value={selectedResourceIds}
                        placeholder="Resources"
                        className="e-field"
                    />
                </td>
            </tr>

            <tr>
                <td className="e-textlabel">Display Color</td>
                <td colSpan={4}>
                    <DropDownListComponent
                        placeholder='Display Color'
                        id="DisplayColor"
                        value={selectedColor}
                        data-name="displayColor"
                        dataSource={colorDescriptionList}// dataSource={colorKeys}
                        fields={{
                            text: 'text',
                            value: 'id',
                        }}
                        className="e-field"
                        change={evt => { setSelectedColor(selectedColor = evt?.value as string) }}
                    />
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">Installer Notes</td>
                <td colSpan={4}>
                    <textarea
                        id="InstallerNotes"
                        className="e-field e-input"
                        style={{ width: '100%' }}
                        placeholder="Add Notes Here"
                        data-name="installerNote"
                        value={installerNotesValue}
                        onChange={evt => { setInstallerNotesValue(installerNotesValue = evt?.target.value as string) }}
                    ></textarea>
                </td>
            </tr>
                        
            <tr>
                <td className="e-textlabel">Attach Files</td>
                <td {...getRootProps()} hidden = { loadingState }>
                    <input {...getInputProps()} id="attachment" name="attachment" multiple={true} />
                    {
                        isDragActive ?
                            <p className='highlighted-text'>Drop the files here ...</p> :
                            <p>
                                <span className='highlighted-text'>Drag and drop some files here, or click to select files</span><br/>
                            </p>
                            
                    }
                    <div className="list-group mt-2" hidden={attachmentNames === ""} list-style-type={'none'}>
                        {attachmentNames.split('  ').length > 0 && attachmentNames.split('%2C%2C').map(acceptedFile => (
                            <div className="new-attachment-names" key={acceptedFile}>
                                {acceptedFile}
                            </div>
                        ))}
                    </div>
                </td>
                <td hidden={!loadingState}>
                    <ProgressBar />
                </td>
            </tr>
            <tr>
                <td className="e-textlabel">Uploaded Files</td>
                <td>
                    <table>
                        <tbody>
                            {fileHyperLinkObjArr.length > 0 && fileHyperLinkObjArr.map(el => (
                                <tr key={el.fileName}>
                                    <td>{el.fileName}</td><td><button onClick={() => deleteAttachmentRecord( encodeURI(el.fileURL), encodeURI(el.fileName), fileHyperLinkObjArr )} className="highlighted-text">x</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><span className='info-text'>( The file list will refresh once you click save. )</span></td>
            </tr>
        </tbody>
    </table>;

}

interface IEachGenericSearchResult {
    value: number | string | boolean | null;
    text: number | string | boolean | null;
}
interface IInstallJob {
    name: IEachGenericSearchResult;
    altname: IEachGenericSearchResult;
    internalid: IEachGenericSearchResult;
    job_type: IEachGenericSearchResult;
    job_status: IEachGenericSearchResult;
    job_customer: IEachGenericSearchResult;
    job_aaa_location: IEachGenericSearchResult;
    job_date_start: IEachGenericSearchResult;
    job_date: IEachGenericSearchResult;
    job_time: IEachGenericSearchResult;
    job_location: IEachGenericSearchResult;
    job_loc_name: IEachGenericSearchResult;
    job_install_details: IEachGenericSearchResult;
    installer_notes: IEachGenericSearchResult;
    attachment_names: string | undefined;
    attachment_paths: string | undefined;
}

export interface IResourceAllocationEvent {
    IsAllDay?: boolean;
    Id: string;
    Subject: string;
    EventColor?: Color;
    StartTime?: Date;
    EndTime?: Date;
    EmployeeIds: string[];
    EquipmentIds: string[];
    ResourceIds: string[];
    InstallId?: string;
    jobDetails?: IInstallJob;
    resourceGroupId?: string;
    allResources?: InstallResourceSet;
    displayColor?: string;
    installerNote?: string;
}

const resolveAppointmentColor = (displayColor: string, install?: IInstall,): Color => {
    // console.log("resolveAppointmentColor triggered in ResourceCalendar, displayColor", displayColor);

    if (!install || !install.tranId || !install.tranId.length) {
        return Color.GRAY;
    }

    if (displayColor !== "") {
        let colorNames: string[] = Object.keys(Color);
        if (colorNames.indexOf(displayColor) !== -1) {
            switch (colorNames.indexOf(displayColor)) {
                case 0: return Color.TURQUOISE;
                case 1: return Color.PINK;
                case 2: return Color.GREEN;
                case 3: return Color.PURPLE;
                case 4: return Color.GOLD;
                case 5: return Color.PLUM;
                case 6: return Color.RED;
                case 7: return Color.PERIWINKLE;
                case 8: return Color.OLIVE;
                case 9: return Color.MAGENTA;
                case 10: return Color.GRAY;
                case 11: return Color.SKYBLUE;
                default: return Color.GRAY;
            }
        }
    } else if (install.jobStatusId === InstallStatus.PENDING_SAVE_THE_DATE || install.jobStatusId === InstallStatus.SAVE_THE_DATE) {
        return Color.GOLD;
    } else {

        switch (install.jobType.text) {
            case InstallJobTypeLabel[1]: return Color.GREEN;//TURQUOISE
            case InstallJobTypeLabel[2]: return Color.RED;//PINK
            case InstallJobTypeLabel[3]: return Color.PLUM;//GREEN
            case InstallJobTypeLabel[4]: return Color.GREEN;
            case InstallJobTypeLabel[5]: return Color.SKYBLUE;
            // case '4': return Color.PURPLE;
            // case '5': return Color.GOLD;
            // case '6': return Color.PLUM;
            // case '7': return Color.RED;
            // case '8': return Color.PERIWINKLE;
            // case '9': return Color.OLIVE;
            // case '10': return Color.MAGENTA;
            default: return Color.GRAY;
        }
    }
    return Color.GRAY;//Added to avoid return type issue; Unreachable line of code in normal cases

    //const lastDigitOfTranId = parseInt(install.tranId.substr(install.tranId.length - 1, 1), 10);



}

/**
 * Function to convert time selected in the web app calendar to time in PST timezone
 * @param newDateObj Date object from web app calendar
 * @param currentTimezoneName Name of the local timezone of the system
 * @param currentTimezoneOffset Time offset between the local timezone of the system and UST
 */
const handleTimeZoneVariation = (newDateObj: Date, currentTimezoneName: string, currentTimezoneOffset: number) => {
    
    if ( currentTimezoneName === 'America/Los_Angeles' ) {// Local timezone of system and NetSuite are same
        return newDateObj;
    } else {// System timezone is not America/Los_Angeles
        let timeStringLosAngeles = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Los_Angeles' }).format(newDateObj);// Time value string for time object in America/Los_Angeles
        let timeStringCurrentTimezone = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: currentTimezoneName }).format(newDateObj);// Time value string for time object in current time zone
        let timeArrCurrentTZ = timeStringCurrentTimezone.split(' ');
        let offsetHours = parseInt(timeStringLosAngeles.split("GMT")[1]);// Offset for PST timezone
        let offsetMinutesLA = offsetHours * 60;// Offset between America/Los_Angeles and UST
        
        let pstDateNew = new Date(new Date(newDateObj).setMinutes(new Date(newDateObj).getMinutes() - currentTimezoneOffset - offsetMinutesLA));// Adjusting time value to compensate for time zone difference
        timeStringLosAngeles = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Los_Angeles' }).format(pstDateNew);// Time value string for the new date object converted to 'America/Los_Angeles' time zone
        let timeArrUSLA = timeStringLosAngeles.split(' ');

        // Comparing the day, date, month, year and time
        if (timeArrUSLA[0] !== timeArrCurrentTZ[0] || timeArrUSLA[1] !== timeArrCurrentTZ[1] || timeArrUSLA[2] !== timeArrCurrentTZ[2] || timeArrUSLA[3] !== timeArrCurrentTZ[3] || timeArrUSLA[5] !== timeArrCurrentTZ[5]) { 
            offsetHours = (offsetHours === -7) ? -8 : -7;
            offsetMinutesLA = offsetHours * 60;// Update offset minute value
            pstDateNew = new Date(new Date(newDateObj).setMinutes(new Date(newDateObj).getMinutes() - currentTimezoneOffset - offsetMinutesLA));
            timeStringLosAngeles = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Los_Angeles' }).format(pstDateNew);
        }

        return pstDateNew;
    }
    
}

const resolveEventForResourceAppointment = (allocation: IResourceAllocation, install?: IInstall): IResourceAllocationEvent | any => {
    
    let timezone = new Timezone();
    let currentTimezone: string = timezone.getLocalTimezoneName();
    let currentTimezoneOffset = new Date().getTimezoneOffset();
    const interval = {
        StartTime: handleTimeZoneVariation(new Date(allocation.startDate), currentTimezone, currentTimezoneOffset),
        EndTime : handleTimeZoneVariation(new Date(allocation.endDate), currentTimezone, currentTimezoneOffset)
    }
    let startDatePST = new Date(allocation.startDate).toTimeString();
    let endDatePST = new Date(allocation.endDate).toTimeString();
    const IsAllDay = (
        (interval.StartTime.getDate() !== interval.EndTime.getDate()) &&// The start day and end day will be adjacent days and therefore different
        (startDatePST.split(" ")[0] === '00:00:00' && endDatePST.split(" ")[0]  === '00:00:00')// Time value of start time and end time will be 00:00:00 AM
    );
    let customerNameText = allocation.jobDetails?.job_customer;
    let subjectText = (allocation?.jobDetails?.job_type === "Internal Work") ? allocation?.jobDetails?.altname + " (" + allocation?.jobDetails?.name + ")" : customerNameText + " (" + allocation?.jobDetails?.name + ")";

    return {
        ...interval,
        Id: allocation.id,
        IsAllDay,
        EventColor: resolveAppointmentColor(allocation.displayColor ? allocation.displayColor : "", install),
        Subject: subjectText ?? 'Placeholder',
        EmployeeIds: allocation.employees.map(({ id }) => id),
        EquipmentIds: allocation.equipment.map(({ id }) => id),
        EmployeeNameList: allocation.employees.map(({ text }) => text),
        EquipmentNameList: allocation.equipment.map(({ text }) => text),
        ResourceIds: ResourceUtils.buildResourceIds(allocation),
        InstallId: allocation.installId,
        jobDetails: allocation.jobDetails,
        resourceGroup: allocation.resourceGroup,
        displayColor: allocation.displayColor,
        installerNote: allocation?.jobDetails?.installer_notes,
    };


}

const buildAppointments = (e: any, installs: IInstall[], selectedLocation: GenericSummary | void) => {

    console.log('building appointments', { e, installs, selectedLocation });

    const installsById = installs.reduce((map, inst) => {
        map[inst.id] = inst;
        return map;
    }, {} as { [id: string]: IInstall; });
    console.log('installsById', installsById);

    if (e.actual && e.actual.appointments) {

        e.result = (e.actual.appointments as IResourceAllocation[])
            // .filter(appt => !selectedLocation || !appt.installId || !installsById[appt.installId] || installsById[appt.installId].aaaLocation.id === selectedLocation.id || installsById[appt.installId].aaaLocation.id === '18')
            // Removed the filter to show appointments for an employee with jobs from other locations
            .map(appt => resolveEventForResourceAppointment(appt, appt.installId ? installsById[appt.installId] : undefined));

        // console.log('location based appointments data', e.result)

        e.count = e.result.length;

    }

    // Check for changes in the calendar view
    const timeLineDayActive = document.getElementsByClassName('e-toolbar-item e-views e-timeline-day e-active-view').length > 0? true : false;
    const timeLineWeekActive = document.getElementsByClassName('e-toolbar-item e-views e-timeline-week e-active-view').length > 0? true : false;
    const timeLineWorkWeekActive = document.getElementsByClassName('e-toolbar-item e-views e-timeline-work-week e-active-view').length > 0? true : false;
    const timeLineMonthActive = document.getElementsByClassName('e-toolbar-item e-views e-timeline-month e-active-view').length > 0? true : false;
    let selectedView: string = "";
    if (timeLineDayActive) selectedView = 'TimelineDay';
    else if (timeLineWeekActive) selectedView = 'TimelineWeek';
    else if (timeLineWorkWeekActive) selectedView = 'TimelineWorkWeek';
    else if (timeLineMonthActive) selectedView = 'TimelineMonth';
    else selectedView = 'undefined';
    console.log("Current view selected", selectedView);

    //Fetch date value from calendar header element
    const calendarViewElementValue = document.querySelector('.e-tbar-btn-text');
    const dateValueFromViewElement: string = calendarViewElementValue?.innerHTML ? calendarViewElementValue?.innerHTML: new Date().toLocaleDateString("en-US");
    console.log("Date value from calendarViewElement", calendarViewElementValue?.innerHTML);
    if (selectedView !== 'undefined' && calendarViewElementValue) {
        // Function to update the URL parameters
        updateURLParams(selectedView, dateValueFromViewElement);
    }

}


/**
 * Function to update the URL parameters based on the new Calender view data
 */
const updateURLParams = (calendarView: string, dateHeaderElementValue: string) => {

        // console.log("Inside updateURLParams() function, arguments", { 'calendarView': calendarView, 'dateHeaderElementValue': dateHeaderElementValue });

        let newURL: string = window.location.href;
        let newParamArr: string[] = [];
        let tempParamArr: string[] = [];
        if (newURL !== "" && newURL.split("?").length > 1) {// If URL parameters already present
            tempParamArr = newURL.split("?")[1].split("&");
            // console.log("tempParamArr", tempParamArr);
            if (tempParamArr.length > 0) {
                tempParamArr.map(el => {
                    let tempParamName = el.split("=")[0];
                    if (tempParamName !== 'viewDate' && tempParamName !== 'viewWorkWeek' && tempParamName !== 'viewWeek' && tempParamName !== 'viewMonth') {// Remove parameters added for calendar view change
                        newParamArr.push(el);
                    }
                });
            }
        }
        let newParamValues: string = (newParamArr.length > 0) ? ("?" + newParamArr.join("&") + '&') : "?";// Keep existing parameters if any

        let dateParamValue: string;
        if (calendarView === 'TimelineDay') {
            dateParamValue = (new Date(dateHeaderElementValue).toLocaleDateString("en-US"));
            newParamValues += ("viewDate=" + dateParamValue);
        } else if (calendarView === 'TimelineMonth') {
            dateParamValue = (new Date(dateHeaderElementValue).toLocaleDateString("en-US"));
            newParamValues += ("viewMonth=" + dateParamValue);
        } else if (calendarView === 'TimelineWeek') {
            let tempDateString = dateHeaderElementValue.split(" - ")[0] + (dateHeaderElementValue.split(" - ")[1]).split(",")[1];
            dateParamValue = (new Date(tempDateString).toLocaleDateString("en-US"));
            newParamValues += ("viewWeek=" + dateParamValue);
        } else if (calendarView === 'TimelineWorkWeek') {
            let tempDateString = dateHeaderElementValue.split(" - ")[0] + (dateHeaderElementValue.split(" - ")[1]).split(",")[1];
            dateParamValue = (new Date(tempDateString).toLocaleDateString("en-US"));
            newParamValues += ("viewWorkWeek=" + dateParamValue);
        }

        window.history.pushState({}, newURL.split("?")[0], newParamValues);// Set the new URL parameter values

}

console.log("Event editor");
const buildEditor = (data: EventEditorDynamicProps): (props: any) => JSX.Element => (
    (props) => <EventEditor {...props} allResources={data.allResources} installs={data.installs} allResourceGroups={data.allResourceGroups} location={data.location} allResourceGroupsFiltered={data.allResourceGroupsFiltered} allResourcesFiltered={data.allResourcesFiltered} />
);

export interface IResourceCalendarProps {
    allResources: InstallResourceSet;
    allInstalls: IInstall[];
    allResourceGroups: IResourceGroup[];
    className?: string;
    userData?: UserContextState | null;
    holidays?: InstallHolidayList[];
}

const fields = {
    InstallId: { name: 'InstallId', validation: { required: false, } },
    EmployeeIds: { name: 'EmployeeIds', validation: { required: true, } },
    EquipmentIds: { name: 'EquipmentIds', validation: { required: false, } },
    startTime: { name: 'StartTime', validation: { required: true } },
    endTime: { name: 'EndTime', validation: { required: true } },
    displayColor: { name: 'DisplayColor', validation: { required: false, } },
    installerNote: { name: 'InstallerNotes', validation: { required: true, } },
};


/**
 * Special processing to ensure the editor can only be opened
 * on valid working dates.
 */
const handlePopupOpen = function (evt?: PopupOpenEventArgs): void {
    // console.log('popup open', evt);

    if (!evt) {
        return;
    }


    // Disable QuickInfo for new event creation.
    if (evt.type === 'QuickInfo' && !(evt.data as IResourceAllocationEvent).Id) {
        evt.cancel = true;
    }
    // Only allow creating events on valid work days
    // else if (evt.target && evt.target.classList.contains('e-work-cells')) {
    // evt.cancel = !evt.target.classList.contains('e-work-hours');
    // }

}


const handleEventRendered = (args?: EventRenderedArgs) => {

    if (!args || !args.element) return;

    const { EventColor: PrimaryColor } = (args as any).data as IResourceAllocationEvent;

    if (!PrimaryColor) return;

    args.element.style.backgroundColor = PrimaryColor;

}


const getAttachmentNameListHTML = (props?: any) => {

    // console.log("Inside the getAttachmentNameListHTML() function, Parameters:", args);
    let attachmentHyperLinksList: string = "";
    if (Object.keys(props).length) {
        // console.log("QuickInfo window");

        let attachmentNameArr = (props.jobDetails.attachment_names ? props.jobDetails.attachment_names : "").split(',');
        let attachmentURLArr = (props.jobDetails.attachment_paths ? props.jobDetails.attachment_paths : "").split(',');
        for (let i = 0; i < attachmentNameArr.length; i++) {
            // Adding commas back to file names and urls replaced by '%2C' at the NetSuite side to avoide issues while splitting the concatenated strings from saved search result
            let itemURL = attachmentURLArr[i] ? attachmentURLArr[i].replace('%2C', ',') : "";
            let itemURL1 = itemURL ? new URL(itemURL).href : "";
            let itemName = attachmentNameArr[i] ? attachmentNameArr[i].replace('%2C', ',') : "";
            attachmentHyperLinksList = attachmentHyperLinksList + '<a href=' + itemURL1 + ' target="_blank" rel="noreferrer">' + itemName + '</a><br>';
        }
        if (attachmentHyperLinksList !== undefined) return attachmentHyperLinksList;
        else return "No files";
    }
    return "No files"
}


const ResourceCalendar = React.forwardRef<any, IResourceCalendarProps>(({
    allResources,
    allInstalls,
    allResourceGroups,
    className = '',
    userData,
    holidays
}, ref) => { 
    let location = useSelectedLocation();
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "")   location = { 'id': locationIdCookie, 'text': locationNameCookie };
    const calendarRef = React.useRef<ScheduleComponent>(null);
    const multiRef = useMultiRef(calendarRef, ref);
    const installs = React.useMemo(() => InstallUtils.filterByLocation(allInstalls, location ? location.id : undefined), [location, allInstalls]);
    const allResourcesFiltered = (allResources.employees ?? []).filter((el) => !location || el.locationId === location.id);
    const allResourceGroupsFiltered = (allResourceGroups ?? []).filter((el) => !location || el.location.id === location.id);
    console.log("allResourceGroups", {allResourceGroups, allResourceGroupsFiltered});
    const editorTemplate = React.useMemo(() => buildEditor({ allResources, installs, allResourceGroups, location, allResourcesFiltered, allResourceGroupsFiltered}), [allResources, installs, allResourceGroups, location, allResourcesFiltered, allResourceGroupsFiltered]);
    const handleDataBind = React.useCallback((e: any) => buildAppointments(e, allInstalls, location), [allInstalls, location]);
    console.log("calendarRef", calendarRef);
    // var [showInstallDetailsFull, setShowInstallDetailsFull] = React.useState(true);

    const currentURL = window.location.search;
    let urlParams = new URLSearchParams(currentURL);
    let selectedViewDateValue = urlParams.get('viewDate');
    let selectedViewMonthValue = urlParams.get('viewMonth');
    let selectedViewWeekValue = urlParams.get('viewWeek');
    let selectedViewWorkWeekValue = urlParams.get('viewWorkWeek');
    
    let currentViewValue: View = 'TimelineMonth';// Default view is set as TimelineMonth
    let selectedDateValue = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

    if (selectedViewDateValue !== "" && selectedViewDateValue !== null) {
        console.log('currentDate', selectedViewDateValue);
        currentViewValue = 'TimelineDay';
        selectedDateValue = new Date(selectedViewDateValue);
    } else if (selectedViewMonthValue !== "" && selectedViewMonthValue !== null) {
        console.log('currentMonth', selectedViewMonthValue);
        currentViewValue = 'TimelineMonth';
        selectedDateValue = new Date(selectedViewMonthValue);
    } else if (selectedViewWeekValue !== "" && selectedViewWeekValue !== null) {
        console.log('currentWeekDay', selectedViewWeekValue);
        currentViewValue = 'TimelineWeek';
        selectedDateValue = new Date(selectedViewWeekValue);
    } else if (selectedViewWorkWeekValue !== "" && selectedViewWorkWeekValue !== null) {
        console.log('currentWeekDay', selectedViewWeekValue);
        currentViewValue = 'TimelineWorkWeek';
        selectedDateValue = new Date(selectedViewWorkWeekValue);
    }else if ((selectedViewDateValue === "" || selectedViewDateValue === null) && (selectedViewMonthValue === "" || selectedViewMonthValue === null) &&
        (selectedViewWeekValue === "" || selectedViewWeekValue === null) && (selectedViewWorkWeekValue === "" || selectedViewWorkWeekValue === null)) {
        console.log('No view change parameters found');
    }

    const calendarResources = React.useMemo(() => (
        ResourceUtils
            .groupMergedByPrimaryGroup(ResourceUtils.mergeSetIntoList(allResources), allResourceGroups)
            // .filter(({ locationId }) => !location || locationId === location.id)
            .filter((el) => !location || el.locationId === location.id || !('locationId' in el))
    ), [allResources, allResourceGroups, location]);

    // Ensures calendar events can't overlap
    // const handleActionBegin = React.useCallback((evt?: ActionEventArgs) => {

    //     if (!evt || !calendarRef.current) {
    //         return;
    //     } else if (evt.requestType === 'eventCreate' || evt.requestType === 'eventChange') {

    //         let data: any;

    //         if (evt.requestType === 'eventCreate') {
    //             data = (evt.data as any)[0];
    //         } else if (evt.requestType === 'eventChange') {
    //             data = evt.data;
    //         }

    //         console.log('checking create!', data, calendarRef.current.isSlotAvailable(data));

    //         if (data && !calendarRef.current.isSlotAvailable(data)) {
    //             evt.cancel = true;
    //         }

    //     }

    // }, []);


    
    const handleActionBegin = React.useCallback((args?: any) => {
        // console.log("handleActionBegin function triggered, args", args);
        if (!args || !calendarRef.current) {
            // console.log("handleActionBegin function !args || !calendarRef.current");
            return;
        } else if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
            console.log("handleActionBegin function args.requestType === 'eventCreate' || args.requestType === 'eventChange'");
            let data: any;
            if (args.requestType === 'eventCreate') {
                console.log("handleActionBegin function args.requestType === 'eventCreate'");
                data = (args.data as any)[0];
            } else if (args.requestType === 'eventChange') {
                console.log("handleActionBegin function args.requestType === 'eventChange'");
                data = args.data;
            }
            if (data.EndTime <= data.StartTime) {
                args.cancel = true;
                alert("The end time should be greater than the start time");
            }
            console.log("Data on actionBegin", data);
        }
    }, []);

    /**
     * Function to exapnd and reduce the text content in a big text field
     */
    const showHideContent = (id1: string, id2: string) => {
        // console.log("Inside the showHideContent() function");
        let textElement: HTMLElement = document.getElementById(id1) as HTMLElement;
        let textElement2: HTMLElement = document.getElementById(id2) as HTMLElement;
        // console.log("{textElement, textElement2}", {textElement, textElement2});
        textElement.style.display = 'none';
        textElement2.style.display = 'inherit';
    }

    const QuickInfoContent = (props: any) => {
        // console.log('QuickInfoContent props', props)

        // We're not rendering an event,
        // we're rendering a 'create event'
        if (!props?.Id || Object.keys(props.jobDetails).length === 0) {
            return null;
        } else {
            let jobName = props.jobDetails.altname;
            let jobMainLocation = props.jobDetails.job_aaa_location;
            let jobCustomer = props.jobDetails.job_customer;
            let jobDate = props.jobDetails.job_date;
            let jobDateStart = props.jobDetails.job_date_start;
            let jobLocName = props.jobDetails.job_loc_name;
            let jobStatus = props.jobDetails.job_status;
            let jobTime = props.jobDetails.job_time;
            let jobType = props.jobDetails.job_type;
            let tempJobInstallDetails: string = props.jobDetails.installation_details;
            let jobInstallDetailsNew = linkifyHtml(stylizePopUpTextFields(tempJobInstallDetails), {target: "_blank", });
            let jobInstallDetailsShort: string = linkifyHtml(shortenText(jobInstallDetailsNew), {target: "_blank", });
            let jobRecordLink = process.env.REACT_APP_NS_JOB_LINK + props.jobDetails.internalid;
            let tempInstallerNoteValue: string = props.installerNote;
            let installerNoteValue: string = linkifyHtml(stylizePopUpTextFields(tempInstallerNoteValue), {target: "_blank", });
            let installerNoteValueShort: string = linkifyHtml(shortenText(installerNoteValue), {target: "_blank", });
            let createdDtae = props.jobDetails.created_date;
            let salesteam = props.jobDetails.salesteam;
            let jobStartTime = props.jobDetails.start_time;
            let jobSurveyor = props.jobDetails.job_surveyor;
            let jobBanner = props.jobDetails.banner_disposition;
            let jobLaborShift = props.jobDetails.labor_shift;
            let jobContactName = props.jobDetails.contact_name;
            let jobContactPhone = props.jobDetails.contact_phone;
            let jobContactMobile = props.jobDetails.contact_mobile;
            let jobAddress1 = props.jobDetails.address1;
            let jobAddress2 = props.jobDetails.address2;
            let jobCity = props.jobDetails.city;
            let jobState = props.jobDetails.state;
            let jobZipCode = props.jobDetails.zip_code;
            let jobLocNotes = stylizePopUpTextFields(props.jobDetails.location_notes);
            let jobLocNotesShort = shortenText(jobLocNotes);
            let jobId = props.jobDetails.name;
            let selectedEmployeeNames = (props.EmployeeNameList).map((el: string) => {
                let tempSplit = (el.split(' '));
                tempSplit.shift();
                return tempSplit?.join(" ");
            }).join(',');//(props.EmployeeNameList).map((el: string) =>{return (el.split(' ')).shift().join(" ")}).join(', ');
            let selectedEquipmentNames = props.EquipmentNameList.join(',');
            if (props.jobDetails.attachment_names && props.jobDetails.attachment_paths) {
                let attachmentNameArr = (props.jobDetails.attachment_names ? props.jobDetails.attachment_names : "").split(',');
                let attachmentURLArr = (props.jobDetails.attachment_paths ? props.jobDetails.attachment_paths : "").split(',');
                let attachmentHyperLinks = "";
                for (let i = 0; i < attachmentNameArr.length; i++) {
                    // Adding commas back to file names and urls replaced by '%2C' at the NetSuite side to avoide issues while splitting the concatenated strings from saved search result
                    let itemURL = attachmentURLArr[i] ? attachmentURLArr[i].replace('%2C', ',') : "";
                    let itemURL1 = itemURL ? new URL(itemURL).href : "";
                    let itemName = attachmentNameArr[i] ? attachmentNameArr[i].replace('%2C', ',') : "";
                    attachmentHyperLinks = attachmentHyperLinks + '<a href=' + itemURL1 + ' target="_blank" rel="noreferrer">' + itemName + '</a><br>';
                }
            }
            let breakTagRegx = new RegExp("<br/>", "gi");
            return (
                <div className="e-date-time">
                    <div className="e-date-time-wrapper e-text-ellipsis">
                        <div className="fixed-height-scrollbar-quickinfo-popup">
                            <div><b>Job Name</b> : <a href={jobRecordLink} target="_blank" rel="noreferrer">{jobName}</a></div>
                            <div><b>Job Id</b> : {jobId}</div>
                            <div><b>Primary Customer</b> : {jobCustomer}</div>
                            <div hidden={!selectedEmployeeNames}><b>Employees Assigned</b> : {selectedEmployeeNames}</div>
                            <div hidden={!selectedEquipmentNames}><b>Equipments Assigned</b> : {selectedEquipmentNames}</div>
                            <div><b>Date Created</b> : {createdDtae}</div>
                            <div><b>Job Status</b> : {jobStatus}</div>
                            <div><b>Job Type</b> : {jobType}</div>
                            <div><b>Sales Team</b> : {salesteam}</div>
                            <div><b>Start Date-Start Time</b> : {jobDateStart}  {jobStartTime}</div>
                            <div><b>Due Date-Due Time</b> : {jobDate}  {jobTime}</div>
                            <div><b>Surveyor</b> : {jobSurveyor}</div>
                            <div><b>Banner Disposition Upon Removal</b> : {jobBanner}</div>
                            <div><b>AAA Install Crew Location</b> : {jobMainLocation}</div>
                            <div><b>Labour shift</b> : {jobLaborShift}</div>
                            <div><b>Location Name</b> : {jobLocName}</div>
                            <div><b>Contact Name</b> : {jobContactName}</div>
                            <div><b>Contact Phone</b> : {jobContactPhone}</div>
                            <div><b>Contact Mobile</b> : {jobContactMobile}</div>
                            <div><b>Address1</b> : {jobAddress1}</div>
                            <div><b>Address2</b> : {jobAddress2}</div>
                            <div><b>City/State/Zip Code</b> : {jobCity}, {jobState} {jobZipCode}</div>
                            <div hidden={ (jobLocNotes.match(breakTagRegx) || []).length >= 4 }><b>Location Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobLocNotes }}></span></div>
                            <div hidden={ (jobLocNotes.match(breakTagRegx) || []).length < 4 }>
                                <div id="showmorelocnotes"><b>Location Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobLocNotesShort }}></span>....<br /><button className="expand-button-class" type="button" onClick={() => showHideContent('showmorelocnotes', 'showlesslocnotes')}>Show More</button></div>
                                <div id="showlesslocnotes" hidden={true}><b>Location Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobLocNotes }}></span><br /><button className="shrink-button-class" type="button" onClick={() => showHideContent('showlesslocnotes', 'showmorelocnotes')}>Show Less</button></div>
                            </div>
                            <div hidden={ (jobInstallDetailsNew.match(breakTagRegx) || []).length >= 4 }><b>Installation Details</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobInstallDetailsNew }}></span></div>
                            <div hidden={ (jobInstallDetailsNew.match(breakTagRegx) || []).length < 4 }>
                                <div id="showmoretext"><b>Installation Details</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobInstallDetailsShort }}></span>....<br /><button className="expand-button-class" type="button" onClick={() => showHideContent('showmoretext', 'showlesstext')}>Show More</button></div>
                                <div id="showlesstext" hidden={true}><b>Installation Details</b> :<br /><span dangerouslySetInnerHTML={{ __html: jobInstallDetailsNew }}></span><br /><button className="shrink-button-class" type="button" onClick={() => showHideContent('showlesstext', 'showmoretext')}>Show Less</button></div>
                            </div>
                            <div hidden={ (installerNoteValue.match(breakTagRegx) || []).length >= 4 }><b>Installer Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: installerNoteValue }}></span></div>
                            <div hidden={ (installerNoteValue.match(breakTagRegx) || []).length < 4 }>
                                <div id="showmorenotes"><b>Installer Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: installerNoteValueShort }}></span>....<br /><button className="expand-button-class" type="button" onClick={() => showHideContent('showmorenotes', 'showlessnotes')}>Show More</button></div>
                                <div id="showlessnotes" hidden={true}><b>Installer Notes</b> :<br /><span dangerouslySetInnerHTML={{ __html: installerNoteValue }}></span><br /><button className="shrink-button-class" type="button" onClick={() => showHideContent('showlessnotes', 'showmorenotes')}>Show Less</button></div>
                            </div>
                            <div><b>Files Attachments</b> :<div dangerouslySetInnerHTML={{ __html: getAttachmentNameListHTML( props ) ? getAttachmentNameListHTML( props ) : "" }}></div></div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    /**
    * Add background color if holiday
    */
    const addBackgroundColor = (args?: RenderCellEventArgs) => {

        if (holidays) {
            for (let i = 0; i < holidays.length; i++) {
                let test = new Date(holidays[i].holidayDate.toString());
                let appDate = args?.date;

                if (((args?.elementType === 'monthCells') || (args?.elementType === 'workCells')) && (appDate?.getDate() === test?.getDate()) && (appDate?.getMonth() === test?.getMonth()) && (appDate?.getFullYear() === test?.getFullYear())) {
                    addClass([args?.element], 'holidayColor');
                }
            }
        }
    }

    /**
     * Add line breaks based on pop-up width to Installation Details, Installer note fields as provided in NetSuite field to improve readability
     */
    const stylizePopUpTextFields = (installationDetailsText: string) => {

        let tempJobInstallDetailsArr = installationDetailsText.split(/\r\n\r\n|\n/);
        return '<span>' + tempJobInstallDetailsArr.join("<br/>") + '</span>';

    }

    /**
     * Create a short length HTML text with maximum of 3 line breaks or <br>
     */
    const shortenText = (HTMLText: string) => {

        let tempHTMLTextArr = HTMLText.split(new RegExp("<br/>", "gi"));
        if (tempHTMLTextArr.length > 0) return '<span>' + tempHTMLTextArr.slice(0, 4).join("<br/>") + '</span>';
        else    return '<span>' + HTMLText + '</span>';// No line breaks
    
    }

    // const noAction = (evt?: PopupOpenEventArgs) => {
    //     if (evt)
    //         evt.cancel = true
    //     return true;
    // }


    return (
        <ScheduleComponent
            ref={multiRef}
            height='100vh'
            width='auto'
            className={className}
            workHours={{ start: '00:00', end: '23:59' }}
            timezone='PST'
            selectedDate={selectedDateValue}
            dataBinding={handleDataBind}
            currentView={currentViewValue}
            editorTemplate={editorTemplate}// editorTemplate={userData?.user?.accessLevel === 'FULL' ? editorTemplate : noAction}
            actionBegin={handleActionBegin}
            renderCell={addBackgroundColor} //Add function to specify color of the cell
            popupOpen={handlePopupOpen}//popupOpen={userData?.user?.accessLevel === 'FULL' ? handlePopupOpen : noAction}
            eventRendered={handleEventRendered}
            quickInfoTemplates={{
                content: QuickInfoContent as any,
            }}
            rowAutoHeight = {true}
            eventSettings={{
                dataSource,
                fields,
                enableTooltip: true,
                allowAdding: (userData?.user?.accessLevel === 'FULL') ? true : false,
                allowDeleting: (userData?.user?.accessLevel === 'FULL') ? true : false,
                allowEditing: (userData?.user?.accessLevel === 'FULL') ? true : false,
                //resourceColorField: 
            }}
            group={{
                allowGroupEdit: true,
                enableCompactView: false,
                resources: ['Resources']
            }}
        >
            <ViewsDirective>
                <ViewDirective option='TimelineDay'/>
                <ViewDirective option='TimelineWeek'/>
                <ViewDirective option='TimelineWorkWeek' />
                <ViewDirective option='TimelineMonth'/>
            </ViewsDirective>

            <ResourcesDirective>
                <ResourceDirective
                    field='ResourceIds'
                    title='Resource Name'
                    name='Resources'
                    allowMultiple={true}
                    dataSource={calendarResources}
                    textField='text'
                    idField='id'
                >
                </ResourceDirective>
            </ResourcesDirective>
            <Inject services={userData?.user?.accessLevel === 'FULL' ? [TimelineViews, TimelineMonth, Resize, DragAndDrop] : [TimelineViews, TimelineMonth]} />
        </ScheduleComponent>
    );

});




export default ResourceCalendar;
