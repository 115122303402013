import { GridComponent, RowDragEventArgs } from '@syncfusion/ej2-react-grids';
import { PaneDirective, PanesDirective, SplitterComponent } from '@syncfusion/ej2-react-layouts';
import { CellClickEventArgs, ResourceDetails } from '@syncfusion/ej2-react-schedule';
import React from 'react';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import { IInstall, InstallUtils } from '../../models/Install';
import { ResourceType } from '../../shared/constants';
import { ResourceUtils } from '../../shared/utils';
import { useConfigContext } from '../ConfigContext';
import { useInstallContext } from '../InstallContext';
import LocationSelector from '../LocationSelector';
import ResourceCalendar, { IResourceAllocationEvent } from '../ResourceCalendar';
import ResourceGroupsGrid, { ResourceGroupTreeItem } from '../ResourceGroupGrid';
import UnallocatedInstallGrid from '../UnallocatedInstallGrid';
import style from './index.module.css';
import { useUserContext } from '../UserContext';
import { getCookie } from '../../services/netsuite';

const ResourceView = () => {
    

    const userValue = useUserContext();
    console.log("Logging user value", userValue);
    const { loading, resources, installs, resourceGroups, holidayList } = useInstallContext();
    // console.log("Logging values in ResourceView", { loading, resources, installs, resourceGroups, holidayList });
    const { locations } = useConfigContext();
    let selectedLocation = useSelectedLocation();
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "")   selectedLocation = { 'id': locationIdCookie, 'text': locationNameCookie };

    const calendarRef = React.useRef<any>(null);
    const installGridRef = React.useRef<GridComponent>(null);
    const resourceGroupGridRef = React.useRef<GridComponent>(null);

    const mainSplitter = React.useRef<SplitterComponent>(null);
    const innerSplitter = React.useRef<SplitterComponent>(null);

    const handleCreated = React.useCallback(() => {

        if (!mainSplitter.current || !innerSplitter.current) {
            return;
        }

        let cont = innerSplitter.current.element.querySelectorAll(".e-pane")[1];
        cont.appendChild(mainSplitter.current.element);

    }, []);

    const handleInstallDrop = (evt?: RowDragEventArgs) => {

        if (!evt || !evt.target || !evt.data || !evt.data.length || !calendarRef.current || !resourceGroupGridRef.current) {
            return;
        }

        const cal = calendarRef.current;
        const [install] = evt.data as IInstall[];

        // Install dropped on calender cell
        if (evt.target.classList.contains('e-work-cells')) {
            let startTime, endTime;
            const cellData: CellClickEventArgs = cal.getCellDetails(evt.target);
            const resourceDetails: ResourceDetails = cal.getResourcesByIndex(cellData.groupIndex);
            if(evt.target.className === 'e-work-cells e-child-node e-work-days' || evt.target.className === 'e-work-cells e-child-node'){ // TimeLineMonth view or other views with each cell showing one complete day
                startTime = new Date(new Date(cellData.startTime).setHours(cellData.startTime.getHours() + 8));
                endTime = new Date(new Date(startTime).setHours(startTime.getHours() + 1)); // Set end time 1 hour larger than start time
            } else { // All other calendar views where each cells contain only upto limited hours/minutes
                endTime = cellData.endTime;
            }
            let displayColorValue = "";
            switch (install['jobType']['text']) {
                case 'Installation': displayColorValue = 'GREEN';
                break;
                case 'Removal': displayColorValue = 'RED';
                break;
                case 'Site Survey': displayColorValue = 'PLUM';
                break;
                case 'Internal Work': displayColorValue = 'SKYBLUE';
                break;
            }
            console.log("displayColorValue", displayColorValue);

            const resourceKey = resourceDetails.resourceData.id.toString();
            const [resourceId, resourceType] = ResourceUtils.getInfoFromKey(resourceKey)
            const eventData: IResourceAllocationEvent = {
                Id: install.id,
                StartTime: startTime,
                EndTime: endTime,
                IsAllDay: cellData.isAllDay,
                Subject: install.tranId,
                displayColor: displayColorValue,
                ResourceIds: [resourceKey],
                EmployeeIds: resourceType === ResourceType.EMPLOYEE ? [resourceId] : [],
                EquipmentIds: resourceType === ResourceType.EQUIPMENT ? [resourceId] : [],
                InstallId: install.id,
            };

            cal.openEditor(eventData, 'Add', true);

        }

        const rowContainer = evt.target.closest('.e-row')
        const targetGridContainer = evt.target.closest('.e-grid');
        const resourceGroupGrid = resourceGroupGridRef.current;

        // Install dropped on resource group cell
        if (rowContainer && targetGridContainer && resourceGroupGrid && targetGridContainer === resourceGroupGrid.element) {

            const rowDataUid = rowContainer.getAttribute('data-uid');

            if (!rowDataUid) {
                return;
            }

            const resourceGroupRow = resourceGroupGrid.getRowObjectFromUID(rowDataUid);

            if (!resourceGroupRow || !resourceGroupRow.data) {
                return;
            }

            const resourceGroupItem: ResourceGroupTreeItem = resourceGroupRow.data as any;

            const interval = InstallUtils.resolveAppointmentInterval(install);
            const eventData: IResourceAllocationEvent = {
                ...interval,
                Id: install.id,
                Subject: install.tranId,
                EmployeeIds: resourceGroupItem.employees.map(({ id }) => id),
                EquipmentIds: resourceGroupItem.equipment.map(({ id }) => id),
                ResourceIds: ResourceUtils.buildResourceIds(resourceGroupItem),
                InstallId: install.id,
            };

            cal.openEditor(eventData, 'Add', true);

        }

        evt.cancel = true;

    };

    const handleGroupDrop = (evt?: RowDragEventArgs) => {


        if (!evt || !evt.target || !evt.data || !evt.data.length || !calendarRef.current || !installGridRef.current) {
            return;
        }

        const cal = calendarRef.current;

        // no longer valid
        const rowContainer = evt.target.closest('.e-row');
        const gridContainer = evt.target.closest('.e-grid');

        const installGrid = installGridRef.current;
        const [resourceGroup] = evt.data as ResourceGroupTreeItem[];

        if (rowContainer && gridContainer && installGrid && gridContainer === installGrid.element) {

            const rowDataUid = rowContainer.getAttribute('data-uid');

            if (!rowDataUid) {
                return;
            }

            const installRow = installGrid.getRowObjectFromUID(rowDataUid);

            if (!installRow || !installRow.data) {
                return;
            }

            const install = installRow.data as IInstall;
            const interval = InstallUtils.resolveAppointmentInterval(install);
            const eventData: IResourceAllocationEvent = {
                ...interval,
                Id: install.id,
                Subject: install.tranId,
                EmployeeIds: resourceGroup.employees.map(({ id }) => id),
                EquipmentIds: resourceGroup.equipment.map(({ id }) => id),
                ResourceIds: ResourceUtils.buildResourceIds(resourceGroup),
                InstallId: install.id
            };

            cal.openEditor(eventData, 'Add', true);

        }

        evt.cancel = true;

    }

    if (loading) {
        return <h1>Loading...</h1>
    }
    if (userValue?.user.accessLevel === 'FULL') {
        
        return <>
        <SplitterComponent id="Expand" separatorSize={3} height="100vh" width="100%" ref={innerSplitter}>
            <PanesDirective>
                <PaneDirective cssClass={style.panel} size='80%' collapsible={false} content={() => (
                    <div className="splitter-content-expand">
                            <ResourceCalendar ref={calendarRef} allResources={resources} allInstalls={installs} allResourceGroups={resourceGroups} className={style.calendar} userData={userValue} holidays={holidayList} />
                    </div>
                )} />
                <PaneDirective collapsible={true} />
            </PanesDirective>
        </SplitterComponent>
        <SplitterComponent id="Collapse" separatorSize={3} orientation="Vertical" ref={mainSplitter} created={handleCreated} >
            <PanesDirective>
                <PaneDirective size='75%' content={() => (
                    <div className="splitter-content-expand">
                        <LocationSelector locations={locations} selectedId={selectedLocation ? selectedLocation.id : undefined} />
                        <UnallocatedInstallGrid ref={installGridRef} allInstalls={installs} className={style.panel} onRowDragStop={handleInstallDrop} />
                    </div>
                )}>
                </PaneDirective>
                <PaneDirective collapsible={false} content={() => (
                    <div className="splitter-content-expand">
                        <ResourceGroupsGrid ref={resourceGroupGridRef} allResources={resources} allLocations={locations} className={style.panel} onRowDragStop={handleGroupDrop} />
                    </div>
                )}>
                </PaneDirective>
            </PanesDirective>
        </SplitterComponent>
        </>
    }else {
        return <>
        <SplitterComponent id="Expand" separatorSize={3} height="100vh" width="100%" ref={innerSplitter}>
            <PanesDirective>
                <PaneDirective cssClass={style.panel} size='100%' collapsible={true} content={() => (
                    <div className="splitter-content-expand">
                            <ResourceCalendar ref={calendarRef} allResources={resources} allInstalls={installs} allResourceGroups={resourceGroups} className={style.calendar} userData={userValue}  holidays={holidayList}/>
                    </div>
                )} />
                <PaneDirective collapsible={true} />
            </PanesDirective>
        </SplitterComponent>
        </>
    }
}

export default ResourceView;
