
export const APP_ID = 2;

export enum Color {
    TURQUOISE = '#75ac98',
    PINK = '#c852c3',
    GREEN = '#71b44a',
    PURPLE = '#6247b6',
    GOLD = '#c5994b',
    PLUM = '#4b2d4a',
    RED = '#c24f3a',
    PERIWINKLE = '#8a92c2',
    OLIVE = '#525434',
    MAGENTA = '#c35e85',
    GRAY = '#888888',
    SKYBLUE = '#87ceeb'
}

export const ColorDescArray = [
    { id: 'GREEN', text: 'Green - Installation' },
    { id: 'RED', text: 'Red - Removal' },
    { id: 'PLUM', text: 'Plum' },
    { id: 'GOLD', text: 'Gold - Save the date' },
    { id: 'TURQUOISE', text: 'Turquoise' },
    { id: 'PINK', text: 'Pink - Vended' },
    { id: 'PURPLE', text: 'Purple - In-House' },
    { id: 'PERIWINKLE', text: 'Periwinkle - Site Survey' },
    { id: 'OLIVE', text: 'Olive - Pole Banner' },
    { id: 'MAGENTA', text: 'Magenta' },
    { id: 'GRAY', text: 'Gray - PTO' },
    { id: 'SKYBLUE', text: 'Sky Blue - Internal Work' },
];

export enum ResourceType {
    EMPLOYEE = 'EMP',
    EQUIPMENT = 'EQP'
}

export const RESOURCE_TYPE_KEY_DELIM = ':'

export enum NetsuiteEnv {
    SANDBOX = 'sandbox',
    PRODUCTION = 'production'
}

export interface IApiEndpoints {
    netsuite: string;
}

export const ApiEndpoints: IApiEndpoints = {
    netsuite: (process.env.REACT_APP_NETSUITE_ENV === NetsuiteEnv.SANDBOX) ?
        'https://4095715-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=101&deploy=1&compid=4095715_SB1&ns-at=AAEJ7tMQRBv3ivH4c6th73Gvap2MsAbYgo6Zxf9dCjOjmQRhcPE' :
        'https://4095715.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=101&deploy=1&compid=4095715&ns-at=AAEJ7tMQ_Kxo1I0cGNEvpAT8LK4mnwbrOexhzAtxrpq8JhOrysM'
};

// eslint-disable-next-line no-restricted-globals
export const SCHEDULE_PROJECT_URL = location.origin; // 'http://localhost:3000';
