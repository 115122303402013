// import { Search } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, GridComponent, RowDD, RowDragEventArgs, Search, ToolbarItems} from '@syncfusion/ej2-react-grids';
import { Inject } from '@syncfusion/ej2-react-navigations';
import React from 'react';
import useSelectedLocation from '../../hooks/useSelectedLocation';
import { IInstall, InstallUtils } from '../../models/Install';
import { getCookie } from '../../services/netsuite';
import { Color } from '../../shared/constants';

export interface UnallocatedInstallListProps {
    allInstalls: IInstall[];
    className?: string;
    onRowDragStop?: (evt?: RowDragEventArgs) => void;
    // popUpShow?: boolean;
}

const DetailTemplate = (props: any) => {
    // console.log("Inside the template", props);
    let lastModified = (new Date(props.lastModified)).toLocaleDateString('en-US');
    // console.log("lastModified", lastModified);
    return (
        <div>
            <h3>{props.jobName}</h3>
            <h3>({props.tranId})</h3>
            <table className="e-table">
                <tbody>
                    <tr>
                        <td>
                            <b>Job Name: </b> {props.jobName}</td>
                    </tr>
                    <tr>
                        <td> <b>Job Id: </b> {props.tranId}</td>
                    </tr>
                    <tr>
                        <td> <b>Primary Customer: </b> {props.customer}</td>
                    </tr>
                    <tr>
                        <td> <b>Job Type: </b> {props.jobType.text}</td>
                    </tr>
                    <tr>
                        <td> <b>Status: </b> {props.status.text}</td>
                    </tr>
                    <tr>
                        <td> <b>Location: </b> {props.aaaLocation.text}</td>
                    </tr>
                    <tr>
                        <td> <b>Created On: </b> {props.created}</td>
                    </tr>
                    <tr>
                        <td> <b>Start Date: </b> {props.startDate} {props.startTime}</td>
                    </tr>
                    <tr>
                        <td> <b>Due Date: </b> {props.duedate} {props.duetime}</td>
                    </tr>
                    <tr>
                        <td> <b>Last Modified: </b> {lastModified}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        )
}
const UnallocatedInstallGrid = React.forwardRef<GridComponent, UnallocatedInstallListProps>((
    {
        allInstalls,
        className = '',
        onRowDragStop,
    },
    ref
) => {

    let location = useSelectedLocation();
    let locationIdCookie: string | null = getCookie('location_id');
    let locationNameCookie: string | null = getCookie('location_name');
    if (locationIdCookie !== null && locationIdCookie !== "" && locationNameCookie !== null && locationNameCookie !== "")   location = { 'id': locationIdCookie, 'text': locationNameCookie };

    
    const toolbarOptions: ToolbarItems[] = ['Search'];
    const installs = React.useMemo(() => {
        return InstallUtils.filterByLocation(allInstalls, location ? location.id : undefined)
    }, [location, allInstalls]);
    const jobTypeColorTemplate = (install: IInstall) => {
        const jobTypeColors: { [key: string]: string } = { "Installation": Color.GREEN, "Removal": Color.RED, "Site Survey": Color.PERIWINKLE, "Relocation": Color.TURQUOISE, "Internal Work": Color.SKYBLUE };
        const colorValue = jobTypeColors[install.jobType.text] || Color.GRAY;
        return (
            <div style={{ backgroundColor: colorValue, padding: '5px', height: '100%', width: '100%' }}></div>
        );
    };
      
    return (
        <GridComponent
            style={{ minHeight: '70vh' }}
            toolbar={toolbarOptions}
            allowFiltering={true}
            filterSettings= {{ 
                type: 'Menu',
                mode: 'Immediate',
                showFilterBarStatus: true,
                immediateModeDelay: 500,
                ignoreAccent: true,
            }}
            className={className}
            dataSource={installs}
            allowRowDragAndDrop={true}
            ref={ref}
            rowDrop={onRowDragStop}
            rowDropSettings={{
                targetID: 'something-that-will-never-actually-exist'
            }}
            editSettings={{
                allowEditing: true,
                allowAdding: false,
                allowDeleting: false,
                allowEditOnDblClick: true,
                headerTemplate:() => {
                    return ("");
                },
                template: (props: any) => <DetailTemplate{...props}/>,
                footerTemplate:() => {
                    return ("");
                },
                mode: 'Dialog',
              
                showConfirmDialog: false,
                showDeleteConfirmDialog: false,
                allowNextRowEdit:false,
            }}
        >
            <ColumnsDirective>
                <ColumnDirective field='id' headerText='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                <ColumnDirective field='jobType.text' headerText='' width='20' template={jobTypeColorTemplate} filter={ { type: 'CheckBox' } } ></ColumnDirective>
                <ColumnDirective field='tranId' headerText='Job Id' width='100' ></ColumnDirective>
                <ColumnDirective field='companyname' headerText='Customer' width='100'></ColumnDirective>
                <ColumnDirective field='jobName' headerText='Job Name' width='100'></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[RowDD, Search]} />
        </GridComponent>
    );

});




export default UnallocatedInstallGrid;
